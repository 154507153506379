import $ from 'jquery';
import {Component} from "javascripts/lib/component";

export class FacebookSocialLoader extends Component {
  init(facebookAppId) {
    this.facebookAppId = facebookAppId;
    window.fbAsyncInit = () => this.initializeFacebookSDK();

    if (!window.fbFetched) {
      window.fbFetched = true;
      $.ajax({dataType: "script", cache: true, url: 'https://connect.facebook.net/en_US/sdk.js'});
    }
  }

  initializeFacebookSDK() {
    window.FB.init({
      appId: this.facebookAppId,
      status: true,
      cookie: true,
      xfbml: true,
      version: 'v9.0'
    });
  }
}

