import $ from 'jquery';
import _ from 'underscore';
import {Component} from "javascripts/lib/component";
import {AddonListItem} from "javascripts/store/product_detail_pages/_addon_list_item";
import 'javascripts/find_by_hook';

export class FormCartItem extends Component {
  init() {
    this.product = this.options.product;

    this._onVariantChange = this.options.onVariantChange;
    this._onSelectedOptionsChange = this.options.onSelectedOptionsChange;
    if (this.options.onSubmit) {
      this.$el.on('submit', this.options.onSubmit);
    }

    this.$addonListItems = this.hook('addon-list-item');
    this.$variantIdField = this.hook('input-variant-id');
    this.$selectOptionValue = this.hook('select-povvv');
    this.$selectDesignValue = this.hook('select-design');
    this.$designForms = this.hook('design-forms');
    this.$designFormContainer = this.hook('design-form-container');
    this.$errors = this.hook('cart-form-errors');

    this.$btnAddToCart = this.hook('btn-add-to-cart');

    this.selectedOptions = this.product['selected_options'];

    this._setSelections();

    this._bindOptionValueControls();
    this._bindDesignValueControls();

    this._initAddonListItems();
  }

  // NOTE: this can be removed when we add support for putting selected options in the URL
  //
  // because browsers keep form field values when navigating back (using the back button),
  // we need to reconstruct the state of the form when it was last submitted
  _setSelections() {
    let that = this;
    let $select;

    _.each(this.$selectOptionValue, function (el) {
      $select = $(el);
      that._processSelection($select);
    });

    this._updateVariant();

    if (this.$addonListItems.length) {
      this._updateSelectedAddons();
    }
  }

  _updateSelectedAddons() {
    this.$addonListItems.each(function () {
      let $li = $(this);
      if ($li.find('.addon-list-item-toggle').is(':checked')) {
        $li.findByHook('input-addon-line-item').prop('disabled', false);
      }
    });
  }

  _initAddonListItems() {
    let addonListItem;
    this.$addonListItems.each(function (i, el) {
      addonListItem = new AddonListItem(el);
      addonListItem.init();
    });
  }

  _bindDesignValueControls() {
    if (this.$selectDesignValue.length <= 0) {
      return;
    }

    this.$designForms.detach();
    this._swapDesignForm();
    this.$selectDesignValue.on('change', this._swapDesignForm.bind(this));
  }

  _bindOptionValueControls() {
    this.$selectOptionValue.on('change', this._onOptionValueChange.bind(this));
  }

  _onOptionValueChange(e) {
    let $select = $(e.target);

    this._processSelection($select);
    this._updateVariant();
  }

  _processSelection($select) {
    let optionName = $select.data('option-name');
    let optionValue = $select.val();

    this._updateSelectedOptions(optionName, optionValue);
    this._onSelectedOptionsChange(this.selectedOptions);
  }

  _updateVariant() {
    let variantId = this._variantIdFor(this.selectedOptions);

    if (variantId) {
      this._setVariantId(variantId);
    } else {
      this._setVariantId(null);
    }
  }

  _selectedDesignId() {
    if (this.$selectDesignValue.length > 1) {
      return this.$selectDesignValue.filter(':checked').val();
    } else {
      return this.$selectDesignValue.val();
    }
  }

  _swapDesignForm() {
    let id = this._selectedDesignId();
    this.$designFormContainer.children().appendTo(this.$designForms);
    this.$designForms.find('[data-design-id="' + id + '"]').appendTo(this.$designFormContainer);
  }

  _variantIdFor(optionValues) {
    let dict = this.product.variants;

    return _.findKey(dict, function (variant) {
      return _.isEqual(variant['option_values'], optionValues);
    });
  }

  _updateSelectedOptions(optionName, optionValue) {
    if (_.isEmpty(optionValue)) {
      delete this.selectedOptions[optionName];
    } else {
      this.selectedOptions[optionName] = optionValue;
    }
  }

  _setVariantId(variantId) {
    // Normalize blank variant field values to 'null'
    let oldVariantId = this.$variantIdField.val() || null;

    if (oldVariantId !== variantId) {
      this._clearErrors();
    }

    this.$variantIdField.val(variantId);

    this._updateButtons(variantId);

    if (this._onVariantChange) {
      this._onVariantChange(variantId);
    }
  }

  _updateButtons(variantId) {
    let variant = this.product.variants[variantId];

    if (variantId === null) {
      // Intentional no-op; leave button in default state
    } else if (variant.status === 'sellable') {
      this.$btnAddToCart.html(this.options.submitButtonText.sellable);
      this.$btnAddToCart.attr('disabled', false);
    } else {
      this.$btnAddToCart.html(this.options.submitButtonText[variant.status]);
      this.$btnAddToCart.attr('disabled', true);
    }
  }

  _clearErrors() {
    // Clear errors if the user is switching away from the current variant
    this.$el.find('.invalid-feedback').remove();
    this.$el.find('.field-with-errors select').unwrap();
    this.$errors.hide();
  }
}
