import {Component} from "javascripts/lib/component";
import {FacebookSocialLoader} from "javascripts/store/product_detail_pages/_facebook_social_loader";
import {Twitter} from "javascripts/lib/integrations/twitter";
import {requestIdleCallbackShim} from "javascripts/lib/request_idle_callback";

export class SocialMediaLoader extends Component {
  init(facebookAppId) {
    requestIdleCallbackShim(() => {
      this.initFacebook(facebookAppId);
      Twitter.initializeWidgets();
    });
  }

  initFacebook(facebookAppId) {
    (new FacebookSocialLoader()).init(facebookAppId);
  }
}

