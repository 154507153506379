import lazySizes from 'lazysizes';
import {Component} from "javascripts/lib/component";
import 'javascripts/find_by_hook';

export class Gallery extends Component {
  init() {
    lazySizes.cfg.preloadAfterLoad = false;

    this.$frame = this.hook('gallery-frame');
    this.$nav = this.hook('gallery-nav-thumbnails');
  }

  addOrReplaceVariantSlide(data) {
    this.clearVariantSlides();
    this.addSwiperSlide(data, this.$frame[0]);
    this.addSwiperSlide(data, this.$nav[0]);
  }

  addSwiperSlide(data, swiperTarget) {
    const swiperSlide = `
    <swiper-slide class="gallery-variant-preview">
      <img src="${data.assetUrl}">
    </swiper-slide>`;

    swiperTarget.swiper.prependSlide([swiperSlide]);
    swiperTarget.swiper.slideTo(0, 1, false);
  }

  clearVariantSlides () {
    const swiperVariant = document.querySelector('.gallery-variant-preview');
    if (swiperVariant) {
      this.$frame[0].swiper.removeSlide(0);
      this.$nav[0].swiper.removeSlide(0);
    }
  }
}
