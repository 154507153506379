import { Component } from "javascripts/lib/component";
import { StoreLayout } from "javascripts/layouts/store_layout";
import { Event } from "javascripts/lib/store/event";
import { ProductDetailPage } from 'javascripts/store/product_detail_pages/product_detail_page';

Component.load(class extends StoreLayout {
  didLoad() {
    super.didLoad();
    new ProductDetailPage(this.$el, { product: this.product }).init();
    Event.productDetailViewed(this.data);
  }
});
