// Find elements by their `data-js-hook` attribute
//
// This is used primarily in views and components to find elements
// scoped to the view's / component's element
//
// General example:
//
// $('.element').findByHook('foo-bar');
//
// Component example:
//
// didLoad: function() {
//   this.$el.findByHook('foo-bar');
// }
//
// NB: Components wrap this function as `this.hook` so instead of the above, you can simply do:
//
// didLoad: function() {
//   this.hook('foo-bar');
// }
//
import jQuery from 'jquery';

// TODO: @tim Delete all jquery plugins (they suck as es6 code)
jQuery.fn.findByHook = function (name) {
  let hook = "[data-js-hook='" + name + "']";
  return this.find(hook);
};
