import $ from 'jquery';

/**
 * A basic shim for requestIdleCallback
 *
 * See: https://developers.google.com/web/updates/2015/08/using-requestidlecallback
 *
 * @param callback: a function or other callable that you want to run in browser idle time
 */

function _requestIdleCallback(callback) {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(callback, {timeout: 500});
  } else {
    window.setTimeout(callback, 500);
  }
}

export function requestIdleCallbackShim(callback, options) {
  options = options || {};

  if ('afterLoad' in options && document.readyState !== 'complete') {
    $(window).on('load', () => _requestIdleCallback(callback));
  } else {
    _requestIdleCallback(callback);
  }
}
