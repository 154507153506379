import {Component} from "javascripts/lib/component";
import 'javascripts/find_by_hook';

export class DisplayPrice extends Component {
  init() {
    this.product = this.options.product;
    this.currentVariantId = null;
  }

  setVariantId(variantId) {
    this._onCurrentVariantIdChange(variantId);
  }

  _onCurrentVariantIdChange(variantId) {
    if (variantId) {
      let price = this.product.variants[variantId].price;
      this.$el.findByHook('product-price').text(price);

      let strikethroughPrice = this.product.variants[variantId]['strikethrough_price'];
      this.$el.findByHook('display-strikethrough-price').text(strikethroughPrice);
    }
  }
}
