import _ from 'underscore';

import { Component } from 'javascripts/lib/component';
import { DisplayPrice } from 'javascripts/store/product_detail_pages/_display_price';
import { FormCartItem } from 'javascripts/store/product_detail_pages/_form_cart_item';
import { Gallery } from 'javascripts/store/components/gallery';
import { Notice } from 'javascripts/store/product_detail_pages/_notice';
import { SocialMediaLoader } from 'javascripts/store/product_detail_pages/_social_media_loader';

export class ProductDetailPage extends Component {
  init() {
    this.product = this.data.product;

    this._initNotices();
    this._initGallery();
    this._initDisplayPrice();
    this._initCartItemForm();
    this._initSocialMediaLoader();
  }

  onVariantChange(variantId) {
    this.displayPrice.setVariantId(variantId);
    this.notices.each((i, notice) => notice.setVariantId(variantId));
  }

  onSelectedOptionsChange(selectedOptions) {
    this._loadPhotoFor(selectedOptions);
  }

  _photoUrlFor(optionValues) {
    let dict = this.product["photo_urls_by_option_values_values"];
    let key = _.values(optionValues).sort().join("-");
    return dict[key];
  }

  _loadPhotoFor(selectedOptions) {
    let photoAssetUrl = this._photoUrlFor(selectedOptions);

    if (photoAssetUrl) {
      this.gallery.addOrReplaceVariantSlide({
        assetUrl: photoAssetUrl
      });
    } else {
      this.gallery.clearVariantSlides();
    }
  }

  // Initializers -----------------------------------------
  _initSocialMediaLoader() {
    new SocialMediaLoader().init(this.data.facebookAppId);
  }

  _initCartItemForm() {
    let cartForm = new FormCartItem('form-cart-item', {
      product: this.product,
      submitButtonText: this.data.submitButtonText,
      onVariantChange: this.onVariantChange.bind(this),
      onSelectedOptionsChange: this.onSelectedOptionsChange.bind(this)
    });

    cartForm.init();
  }

  _initDisplayPrice() {
    this.displayPrice = new DisplayPrice("display-price", {
      product: this.product
    });
    this.displayPrice.init();
  }

  _initNotices() {
    this.notices = this.hook('product-notice').map((i, el) => {
      let notice = new Notice(el, this.data);
      notice.init();
      return notice;
    });
  }

  _initGallery() {
    this.gallery = new Gallery('gallery');
    this.gallery.init();
  }
}
