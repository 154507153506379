import {Component} from "javascripts/lib/component";
import {OutOfStockStory} from "javascripts/store/product_detail_pages/_out_of_stock_story";

export class Notice extends Component {

  init() {
    this._initOutOfStockStory();

    this.$notificationLink = this.hook('notification-link');
    this.$headline = this.hook('headline');

    this.noticeType = this.$el.data('notice-type');
    this.headlineTemplate = this.$headline.data('template');
  }

  setVariantId(variantId) {
    if (variantId === null) {
      return; // No variant selected yet
    }

    if (this._productVariantStatus(variantId) === this.noticeType ||
      this._productVariant(variantId)[this.noticeType]) {
      let name = this._productVariantName(variantId);

      this.$el.removeClass('hidden');
      this.$headline.html(this.headlineTemplate.replace(/%{product}/, name));
    } else {
      this.$el.addClass('hidden');
    }

    this.$notificationLink.attr('href', this._productVariantNotificationUrl(variantId));
  }

  _productVariant(variantId) {
    return this.options.product.variants[variantId];
  }

  _productVariantNotificationUrl(variantId) {
    return this._productVariant(variantId)['notification_url'];
  }

  _productVariantName(variantId) {
    return this._productVariant(variantId).name;
  }

  _productVariantStatus(variantId) {
    return this._productVariant(variantId).status;
  }

  _initOutOfStockStory() {
    if (!this.hook("out-of-stock-story").length) {
      return;
    }

    this.outOfStockStory = new OutOfStockStory("out-of-stock-story", {
      storyParts: this.options.outOfStockStoryParts
    });
    this.outOfStockStory.init();
  }
}
