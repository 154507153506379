import {Component} from "javascripts/lib/component";

export class AddonListItem extends Component {
  init() {
    this.$inputToggleAddon = this.hook('input-toggle-addon');
    this.$inputAddonLineItem = this.hook('input-addon-line-item');

    this.$inputToggleAddon.on('change', this._onInputToggleAddonChange.bind(this));
  }

  // disable the hidden fields for the addon to prevent them from being submitted
  _onInputToggleAddonChange(e) {
    e.preventDefault();
    this.$inputAddonLineItem.prop('disabled', !this.$inputAddonLineItem.prop('disabled'));
  }
}

