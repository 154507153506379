import _ from 'underscore';
import {Component} from "javascripts/lib/component";

export class OutOfStockStory extends Component {
  init() {
    // index gets incremented every time the storyButton is clicked and
    // is used as the index into the heading and button text arrays. We
    // don't loop so when index > length of array then we return the value
    // at the last index.
    this.index = 0;
    this.storyParts = this.options.storyParts;
    this.$storyHeadline = this.hook('out-of-stock-story-headline');
    this.$storyContent = this.hook('out-of-stock-story-content');
    this.$storyButton = this.hook('out-of-stock-story-button');

    this.$storyButton.click(function (e) {
      e.preventDefault();
      this._updateStory();
    }.bind(this));

    this._updateStory();
  }

  _updateStory() {
    this.$storyContent.text(this._generateStory());
    this.$storyHeadline.text(this._valueAtIndexOrLastAvailable(this.storyParts.headings, this.index));
    this.$storyButton.text(this._valueAtIndexOrLastAvailable(this.storyParts.button_text, this.index));
    this.index += 1;
  }

  // negative index -> i = 0
  // out of bounds positive index -> i = array.length - 1
  _valueAtIndexOrLastAvailable(array, index) {
    let i = Math.min(array.length - 1, Math.max(0, index));
    return array[i];
  }

  _generateStory() {
    return _.sample(this.storyParts.subjects) + ' ' + _.sample(this.storyParts.predicates) +
      ' our warehouse last night and decided to ' + _.sample(this.storyParts.problems) +
      ' our entire supply of this product.';
  }
}
